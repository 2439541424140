$breakpoints: (
        lg: 'only screen and (min-width: 1200px)',
        md: 'only screen and (min-width: 768px)',
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint: #{$breakpoint}.";
  }
}


@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/Plus Jakarta Sans.woff');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 55 REGULAR';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-55-REGULAR.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 65 MEDIUM';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-65-MEDIUM.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 75 SEMIBOLD';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-75-SEMIBOLD.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 85 BOLD';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-85-BOLD.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 95 EXTRABOLD';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-95-EXTRABOLD.TTF');
}

$font-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-calli: Baskerville, Georgia, "Liberation Serif", "Kaiti SC", STKaiti, "AR PL UKai CN", "AR PL UKai HK", "AR PL UKai TW", "AR PL UKai TW MBE", "AR PL KaitiM GB", KaiTi, KaiTi_GB2312, DFKai-SB, "TW\-Kai", serif;
$font-serif: Georgia, "Nimbus Roman No9 L", "Songti SC", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung", "WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE", PMingLiU, MingLiU, serif;


$color-page-bg: #F2F2F2; // 页面背景色
$color-view-bg: #FFFFFF; // View视图背景色


$color-divider: #B3B3B3; // View 标题分割线颜色


// ---------------------------------------------------------------------------- //
$color-white: #FFFFFF; // 白色
$color-black: #222222; // 黑色
$color-primary: #7782F7; // 主紫色
$color-btn-primary: #7782F7;
// $color-btn-primary-text === $color-white
$color-btn-primary-hover: #8791F7;
$color-btn-default: #F2F2F2;
// $color-btn-default-text === $color-black
$color-btn-default-hover: #E5E6E6;
$color-btn-neutral: #2B3440;
$color-btn-neutral-text: #D8DDE4;
$color-btn-neutral-hover: #1D232B;
$color-btn-text: rgba(87, 13, 248, 1);
$color-btn-text-hover: rgba(87, 13, 248, 0.75);
$color-btn-disabled: #F2F2F2;
$color-btn-disabled-text: #C5C5C5;

$color-theme-grey3: #F2F2F2;

$color-chatBubble-header-time: #8F949B;
$color-chatBubble-bg-left: #F3F4F8;
$color-chatBubble-bg-right: #2B3440;


@mixin text-regular {
  font-family: '阿里巴巴普惠体 2.0 55 REGULAR', sans-serif;
}

@mixin text-medium {
  font-family: '阿里巴巴普惠体 2.0 65 MEDIUM', sans-serif;
}

@mixin text-semibold {
  font-family: '阿里巴巴普惠体 2.0 75 SEMIBOLD', sans-serif;
}

@mixin text-bold {
  font-family: '阿里巴巴普惠体 2.0 85 BOLD', sans-serif;
}

@mixin text-extrabold {
  font-family: '阿里巴巴普惠体 2.0 95 EXTRABOLD', sans-serif;
}


@mixin text-h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

@mixin text-h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}

@mixin text-h3 {
  font-weight: 500; // medium
  font-size: 20px;
  line-height: 24px;
}

@mixin text-title {
  font-weight: 400; // regular
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04rem;
}

@mixin text-subtitle {
  font-weight: 400; // regular
  font-size: 16px;
  line-height: 22px;
}

@mixin text-sm-medium {
  font-weight: 500; // medium
  font-size: 14px;
  line-height: 20px;
}

@mixin text-mp-medium {
  font-weight: 500; // medium
  font-size: 16px;
  line-height: 22px;
}

@mixin text-sm {
  font-weight: 400; // regular
  font-size: 14px;
  line-height: 20px;
}

@mixin text-mini {
  font-weight: 400; // regular
  font-size: 12px;
  line-height: 14px;
}

@mixin text-10 {
  font-weight: 400; // regular
  font-size: 10px;
  line-height: 12px;
}

@mixin multiline {
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  -moz-hyphens: auto;
}

@mixin ellipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  @include multiline
}

body, page {
  // font-family: $font-sans;
  font-family: '阿里巴巴普惠体 2.0 55 REGULAR', sans-serif;


  --stroke: #EBEDF2;

  --AccentPurple-main: #570DF8;
  --AccentPurple-background: #F4F5FF;

  --DeepBlue-main: #6192F2;
  --DeepBlue-light: #B2CDFF;
  --DeepBlue-background: #F2F7FF;

  --Yellow-main: #F26161;
  --Yellow-light: #FFB2B2;
  --Yellow-background: #FFF2F2;

  --Green-main: #ACE573;
  --Green-light: #F4FAED;
  --Green-background: #F9FFF2;

  --Red-main: #F26161;
  --Red-light: #FFB2B2;
  --Red-background: #FFF2F2;

  --Blue-main: #61C7F2;
  --Blue-light: #B2EBFF;
  --Blue-background: #F2FBFF;

  --Neutral-Foreground-1-Rest: #242424;
  --Neutral-Background-1-Rest: #FFFFFF;

}

html {
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

::selection {
  // @apply bg-nextwallet-primary-100;
  //@apply text-nextwallet-primary-100;
}

img {
  vertical-align: middle;
  user-select: none;
  flex-shrink: 0;
  max-width: unset;
}

p {
  white-space: pre-line;
  margin: 0;
}

span {
  display: inline-block;
  white-space: nowrap;
}


//// Chrome 滚动条样式
//::-webkit-scrollbar {
//  width: 5px;
//  height: 5px;
//}
//
//::-webkit-scrollbar-track {
//  background-color: transparent;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: rgba(0, 0, 0, 0.15);
//  border-radius: 20px;
//  background-clip: content-box;
//  border: 1px solid transparent;
//}






.layout {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}