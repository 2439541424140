$breakpoints: (
        lg: 'only screen and (min-width: 1200px)',
        md: 'only screen and (min-width: 768px)',
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint: #{$breakpoint}.";
  }
}


@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('/assets/fonts/Plus Jakarta Sans.woff');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 55 REGULAR';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-55-REGULAR.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 65 MEDIUM';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-65-MEDIUM.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 75 SEMIBOLD';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-75-SEMIBOLD.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 85 BOLD';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-85-BOLD.TTF');
}

@font-face {
  font-family: '阿里巴巴普惠体 2.0 95 EXTRABOLD';
  src: url('/assets/fonts/ALIBABAPUHUITI-2-95-EXTRABOLD.TTF');
}

$font-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-calli: Baskerville, Georgia, "Liberation Serif", "Kaiti SC", STKaiti, "AR PL UKai CN", "AR PL UKai HK", "AR PL UKai TW", "AR PL UKai TW MBE", "AR PL KaitiM GB", KaiTi, KaiTi_GB2312, DFKai-SB, "TW\-Kai", serif;
$font-serif: Georgia, "Nimbus Roman No9 L", "Songti SC", STSong, "AR PL New Sung", "AR PL SungtiL GB", NSimSun, SimSun, "TW\-Sung", "WenQuanYi Bitmap Song", "AR PL UMing CN", "AR PL UMing HK", "AR PL UMing TW", "AR PL UMing TW MBE", PMingLiU, MingLiU, serif;


$color-page-bg: #F2F2F2; // 页面背景色
$color-view-bg: #FFFFFF; // View视图背景色


$color-divider: #B3B3B3; // View 标题分割线颜色


// ---------------------------------------------------------------------------- //
$color-white: #FFFFFF; // 白色
$color-black: #222222; // 黑色
$color-primary: #7782F7; // 主紫色
$color-btn-primary: #7782F7;
// $color-btn-primary-text === $color-white
$color-btn-primary-hover: #8791F7;
$color-btn-default: #F2F2F2;
// $color-btn-default-text === $color-black
$color-btn-default-hover: #E5E6E6;
$color-btn-neutral: #2B3440;
$color-btn-neutral-text: #D8DDE4;
$color-btn-neutral-hover: #1D232B;
$color-btn-text: rgba(87, 13, 248, 1);
$color-btn-text-hover: rgba(87, 13, 248, 0.75);
$color-btn-disabled: #F2F2F2;
$color-btn-disabled-text: #C5C5C5;

$color-theme-grey3: #F2F2F2;

$color-chatBubble-header-time: #8F949B;
$color-chatBubble-bg-left: #F3F4F8;
$color-chatBubble-bg-right: #2B3440;


@mixin text-regular {
  font-family: '阿里巴巴普惠体 2.0 55 REGULAR', sans-serif;
}

@mixin text-medium {
  font-family: '阿里巴巴普惠体 2.0 65 MEDIUM', sans-serif;
}

@mixin text-semibold {
  font-family: '阿里巴巴普惠体 2.0 75 SEMIBOLD', sans-serif;
}

@mixin text-bold {
  font-family: '阿里巴巴普惠体 2.0 85 BOLD', sans-serif;
}

@mixin text-extrabold {
  font-family: '阿里巴巴普惠体 2.0 95 EXTRABOLD', sans-serif;
}


@mixin text-h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

@mixin text-h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}

@mixin text-h3 {
  font-weight: 500; // medium
  font-size: 20px;
  line-height: 24px;
}

@mixin text-title {
  font-weight: 400; // regular
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04rem;
}

@mixin text-subtitle {
  font-weight: 400; // regular
  font-size: 16px;
  line-height: 22px;
}

@mixin text-sm-medium {
  font-weight: 500; // medium
  font-size: 14px;
  line-height: 20px;
}

@mixin text-mp-medium {
  font-weight: 500; // medium
  font-size: 16px;
  line-height: 22px;
}

@mixin text-sm {
  font-weight: 400; // regular
  font-size: 14px;
  line-height: 20px;
}

@mixin text-mini {
  font-weight: 400; // regular
  font-size: 12px;
  line-height: 14px;
}

@mixin text-10 {
  font-weight: 400; // regular
  font-size: 10px;
  line-height: 12px;
}

@mixin multiline {
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  -moz-hyphens: auto;
}

@mixin ellipsis($line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  @include multiline
}

body, page {
  // font-family: $font-sans;
  font-family: '阿里巴巴普惠体 2.0 55 REGULAR', sans-serif;


  --stroke: #EBEDF2;

  --AccentPurple-main: #570DF8;
  --AccentPurple-background: #F4F5FF;

  --DeepBlue-main: #6192F2;
  --DeepBlue-light: #B2CDFF;
  --DeepBlue-background: #F2F7FF;

  --Yellow-main: #F26161;
  --Yellow-light: #FFB2B2;
  --Yellow-background: #FFF2F2;

  --Green-main: #ACE573;
  --Green-light: #F4FAED;
  --Green-background: #F9FFF2;

  --Red-main: #F26161;
  --Red-light: #FFB2B2;
  --Red-background: #FFF2F2;

  --Blue-main: #61C7F2;
  --Blue-light: #B2EBFF;
  --Blue-background: #F2FBFF;

  --Neutral-Foreground-1-Rest: #242424;
  --Neutral-Background-1-Rest: #FFFFFF;

}

html {
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

::selection {
  // @apply bg-nextwallet-primary-100;
  //@apply text-nextwallet-primary-100;
}

img {
  vertical-align: middle;
  user-select: none;
  flex-shrink: 0;
  max-width: unset;
}

p {
  white-space: pre-line;
  margin: 0;
}

span {
  display: inline-block;
  white-space: nowrap;
}


//// Chrome 滚动条样式
//::-webkit-scrollbar {
//  width: 5px;
//  height: 5px;
//}
//
//::-webkit-scrollbar-track {
//  background-color: transparent;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: rgba(0, 0, 0, 0.15);
//  border-radius: 20px;
//  background-clip: content-box;
//  border: 1px solid transparent;
//}






/* 样式覆盖 */

:where(.css-dev-only-do-not-override-htwhyh).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  // border-color: $color-primary;
}

.ant-layout .ant-layout-sider {
  background: #1E2329;
}

.ant-menu-dark, :where(.css-dev-only-do-not-override-14m6ntx).ant-menu-dark > .ant-menu {
  background: #1E2329;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-dev-only-do-not-override-14m6ntx).ant-menu-dark > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #191D22;
}

.ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-checkbox + span {
  //font-family: "Roboto";
  //font-weight: 400; // regular
  margin-top: -2px;
  font-size: 16px;
  line-height: 22px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

:where(.css-dev-only-do-not-override-2btrbv)[class^="ant-btn"], :where(.css-dev-only-do-not-override-2btrbv)[class*=" ant-btn"] {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.ant-popover .ant-popover-inner {
  padding: 4px;
}

.ant-radio-button-wrapper {
  padding-inline: 4px;
  font-size: 12px;
  @include text-regular;
}

:where(.css-dev-only-do-not-override-1k21pk0)[class^="ant-input"], :where(.css-dev-only-do-not-override-1k21pk0)[class*=" ant-input"] {
  font-size: 14px;
  font-family: $font-sans;
}

.ant-btn {
  @include text-regular;
}

// antd 菜单字符样式覆盖
.ant-menu-title-content {
  user-select: none;
  font-size: 14px;
  @include text-regular;
}

// antd 菜单分割线样式覆盖
.ant-menu-item-divider {
  margin-left: 8% !important;
  width: 84% !important;
  border-top-width: 1.25px !important;
  padding: 4px 0 !important;
}

// antd Tabs 下方滑动条样式覆盖
.ant-tabs-ink-bar {
  height: 3.5px !important;
  border-radius: 4px !important;
}

// antd Tabs 字体样式覆盖
.ant-tabs-tab {
  @include text-regular;
}

.ant-tabs-tab-active {
  @include text-medium;
}

.ant-input {
  @include text-regular;
}

.ant-radio-group {
  @include text-regular;
}

// 下拉菜单字体
.ant-dropdown-menu-title-content {
  @include text-regular;
}

:where(.css-dev-only-do-not-override-14m6ntx).ant-drawer .ant-drawer-body {
  height: 72px !important;
}


// 对齐线样式覆盖
.x6-widget-snapline-vertical,
.x6-widget-snapline-horizontal {
  stroke: $color-primary;
  stroke-width: 1px;
}

// 框选样式覆盖
.x6-widget-selection-inner {
  margin-top: -16px;
  margin-left: -16px;
  padding-right: 28px;
  padding-bottom: 28px;
  border-radius: 12px;
  box-shadow: 2px 2px 5px rgba(211, 211, 211, 0.75);
}

.x6-widget-selection-box {
  border-radius: 6px;
  box-shadow: 2px 2px 5px rgba(211, 211, 211, 0.75);
}

// 图形变换样式覆盖
.x6-widget-transform {
  border: none;
}

.x6-widget-transform > div {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}


.rhap_container {
  background-color: transparent !important;
  box-shadow: 0 0 3px 0 rgba(255, 255, 255, 0.35) !important;
}

.rhap_button-clear {
  color: $color-primary !important;
}

.rhap_volume-filled {
  background: $color-primary !important;
}

.rhap_volume-bar {
  background: #C1C6FF !important;
}

.rhap_volume-indicator {
  background: $color-primary !important;
}

.rhap_download-progress {
  background: #C1C6FF !important;
}

.rhap_progress-filled {
  background: $color-primary !important;
}

.rhap_progress-indicator {
  background: $color-primary !important;
}

.rhap_progress-bar-show-download {
  background: rgba(255, 255, 255, 0.25) !important;
}

.rhap_time {
  color: $color-white !important;
}

